import { Navigate, Outlet, Params, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
    Env,
    EnvName,
    EnvStatus,
    EnvType,
} from '../../../../redux/user/userTypes'
import { setGlobalEnv } from '../../../../redux/global/globalSlice'
import { validEnvStatus } from './routers/ChannelRouter'
import AddDistributorsProductTour from '../../productTour/AddDistributorsProductTour'

interface UrlParams {
    env: string
    distributor: string
    route: string
}

const getParamsFromUrl = (params: Readonly<Params<string>>): UrlParams => {
    const url = Object.values(params)[0].split('/')

    return {
        env: url[0],
        distributor: url[1],
        route: url[2],
    }
}

export const EnvLayout = ({ acceptedEnv }: { acceptedEnv: string[] }) => {
    const params = useParams()
    const [hasProductTour, setHasProductTour] = useState<boolean>(false)
    const { env, distributor, route } = getParamsFromUrl(params)
    const { env: userEnv } = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )

    useEffect(() => {
        if (!localStorage.getItem('addDistributorProductTour')) {
            const isFoodservice = window.location.href.includes("food-service")
            if (isFoodservice) {
                setHasProductTour(true)
            }
        }
    }, [])

    // TODO : Add fallback page for food service env (/food-service or /retail only)

    // TODO : Check in local storage if there is a past active env

    // Handle cross exception
    if (env === EnvType.FOOD_SERVICE && distributor === 'cross') {
        _setEnv({
            name: EnvName.FOOD_SERVICE_CROSS,
            type: EnvType.FOOD_SERVICE_CROSS,
            status: EnvStatus.ACTIVATED,
        })

        return <Outlet />
    }

    if (env === EnvType.RETAIl && distributor === 'cross') {
        _setEnv({
            name: EnvName.RETAIL_CROSS,
            type: EnvType.RETAIL_CROSS,
            status: EnvStatus.ACTIVATED,
        })

        return <Outlet />
    }

    // Check for env to exist in user as actived env
    const activeEnv = userEnv.filter(
        ({ status, name, type }) =>
            distributor == name &&
            env === type &&
            validEnvStatus.includes(status)
    )[0]

    if (activeEnv) {
        _setEnv(activeEnv)
        if (route) {
            return <>{hasProductTour && <AddDistributorsProductTour setHasProductTour={setHasProductTour} />}<Outlet /></>
        } else {
            return <Navigate to={`/${env}/${distributor}/dashboard`} />
        }
    }

    if (acceptedEnv.includes(distributor)) {
        return <Navigate to="/distributeurnonconnecte" />
    }

    return <Navigate to="/404" />
}
