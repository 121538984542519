import React from 'react'
import { Grid } from '@mui/material'
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Units } from '../../../../../../redux/global/globalTypes'
import { EvolSellOutNumber, IncrementalNumber, KpiIcon, NumberCard, SecondaryNumberCard } from '../../../../../../components/features/NumberCard';
import DoughnutChart from '../../../../../../components/features/foodService/dashboard/graph/DoughnutChart';
import { Impact, PromotionsKpis, Performance, Cost, UnityType } from '../../../../../../redux/promotions/promotionsTypes';
import { StockageComponent } from './StockageComponent';
import { KpiType } from '../../../../../../components/features/PrimaryKpiCard';

export interface StockageChart {
    sellOut: number,
    sellInGlobal: number,
    sellInPromo: number
}

export const KPIsConfig = (
    KPIs: PromotionsKpis
) => [
        {
            id: 1,
            type: KpiType.NUMBER,
            name: 'Volume sell-in promo',
            icon: <DiscountOutlinedIcon color='primary' />,
            units: Units.kg,
            description: `Volume sell-in promotionné livré au distributeur et son évolution par rapport à A-1.`,
            number: Math.round(KPIs.volumePromo),
            difference: Math.round(KPIs.differencePromo),
            evolution: KPIs.evolutionPromo
        },
        {
            id: 2,
            type: KpiType.NUMBER,
            name: 'Volume sell-in global',
            icon: <LoginOutlinedIcon color='primary' />,
            units: Units.kg,
            description: `Volume sell-in total livré au distributeur et son évolution par rapport à A-1.`,
            number: Math.round(KPIs.volumeSellIn),
            difference: Math.round(KPIs.differenceSellIn),
            evolution: KPIs.evolutionSellIn
        },
        {
            id: 3,
            type: KpiType.NUMBER,
            name: 'Volume sell-out',
            icon: <LogoutOutlinedIcon color='primary' />,
            units: Units.kg,
            description: `Volume sell-out total distribué par le distributeur (sortie de dépôt) et son évolution par rapport à A-1.`,
            number: Math.round(KPIs.volumeSellOut),
            difference: Math.round(KPIs.differenceSellOut),
            evolution: KPIs.evolutionSellOut
        },
        {
            id: 4,
            type: KpiType.GAUGE,
            name: 'Budget',
            units: Units.euro,
            description: `Budget total déclaré et budget investi dans de la promotion sur la période A.`,
            number: Math.round(KPIs.budgetTotal),
            difference: Math.round(KPIs.budgetInvest),
            evolution: Math.round(KPIs.percentageBudget)
        },
    ]

export const impactCardConfig = (impact: Impact) => [
    {
        id: 1,
        title: "Poids promo",
        subtitle: "Sell-in en promo sur sell-in global",
        component: <NumberCard number={impact.promoWeight} difference={impact.differencePromoWeight} unit={Units.percent} icon={KpiIcon.PROMO} />,
        flex: "0 0 20%",
    },
    {
        id: 2,
        title: "Stockage",
        subtitle: "Suivi de l'effet d'aubaine générant des stocks en dépôt.",
        component: <StockageComponent chart={impact.chart} labelGlobal="Sell-in global (kg)" labelPart={"Sell-in promo (kg)"} labelSecondBar={"Sell-out (kg)"} stockagePercentage={impact.stockagePercentage} globalTendency={impact.globalTendency} />,
        flex: "1 1",
    },
    {
        id: 3,
        title: "Contribution à la croissance",
        subtitle: "Impact sur les volumes en sell-out.",
        component: <Grid container direction="row" width="100%" wrap="nowrap" > <IncrementalNumber number={impact.incremental} unit={Units.kg} text='Incrémental sell-out' tooltip="La promotion n’a pas généré de ventes incrémentales." /> <EvolSellOutNumber number={impact.differenceSellOut} difference={impact.evolSellOut} unit={Units.kg} text='Evo sell-out A-1' /> </ Grid>,
        flex: "1 1 100%",
    },

]



export const performanceCardConfig = (performance: Performance) => {
    const unitBasket = performance.unityType === UnityType.UF ? Units.kgPerUFPerMonth : Units.kgPerTransaction

    return [
        {
            id: 1,
            title: "Répartition d'utilisateurs finaux (UF)",
            subtitle: "Répartition des sell-out par typologie d’utilisateur final.",
            component: <Grid mb="2vh">
                <DoughnutChart
                    setFieldSelected={
                        function (field: any): void {
                        }
                    }
                    setOpen={
                        function (open: boolean): void {
                        }
                    } />
            </Grid>,
            flex: "1 1 100%",
        },
        {
            id: 3,
            title: "Evolution du panier moyen mensuel",
            subtitle: "Poids du panier moyen mensuel en sortie entrepôt.",
            component: <NumberCard number={performance.basket} difference={performance.differenceBasket} evol={performance.evolBasket} unit={unitBasket} icon={KpiIcon.VOLUME} kpi />,
            flex: "1 0",
        },
        (performance.unityType === UnityType.UF ? {
            id: 2,
            title: "Evolution nombre d’utilisateurs finaux (UF)",
            subtitle: "Evolution de la distribution numérique (DN).",
            component: <NumberCard number={performance.UF} difference={performance.differenceUF} evol={performance.evolUF} unit={Units.UF} icon={KpiIcon.UF} kpi />,
            flex: "1 0",
        } :
            {
                id: 2,
                title: "Evolution nombre de transactions",
                subtitle: "Evolution de la distribution numérique (DN).",
                component: <NumberCard number={performance.UF} difference={performance.differenceUF} evol={performance.evolUF} unit={Units.transactions} icon={KpiIcon.UF} kpi />,
                flex: "1 0",
            }),
    ]
}

export const costCardConfig = (cost: Cost) => {
    return [
        {
            id: 1,
            title: "Coût d’acquisition du volume incrémental",
            subtitle: "Investissement € par kilo vendu sell-out.",
            component: <SecondaryNumberCard number={cost.CACVA} difference={cost.differenceInvestPerKg} evol={cost.evolInvestPerKg} unit={Units['€/kg']} icon={KpiIcon.VOLUME} tooltip={"L'incrémental étant négatif, nous ne pouvons pas calculer le coût d'actiosition du volume d'affaires incrémental."} />,
            flex: "1 0",
        },
        cost.unityType === UnityType.UF ? {
            id: 2,
            title: "Coût d'acquisition d'un utilisateur final (UF)",
            subtitle: "Investissement € par nouvel UF acheteur.",
            component: <SecondaryNumberCard number={cost.CACUF} difference={cost.differenceCACUF} evol={cost.evolCACUF} unit={Units.euroPerUF} icon={KpiIcon.UF} tooltip={"L'évolution du nombre d'utilisateur final étant négatif, nous ne pouvons pas calculer le coût d'acquisition d'un nouvel utilisateur final."} />,
            flex: "1 1 ",
        } : {
            id: 2,
            title: "Coût d'acquisition d'une transaction",
            subtitle: "Investissement € par nouvelle transaction.",
            component: <SecondaryNumberCard number={cost.CACUF} difference={cost.differenceCACUF} evol={cost.evolCACUF} unit={Units["€PerTransaction"]} icon={KpiIcon.UF} tooltip={"L'évolution du nombre de transaction étant négatif, nous ne pouvons pas calculer le coût d'acquisition d'une nouvelle transaction."} />,
            flex: "1 1 ",
        }
        ,]
}