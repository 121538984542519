import { Grid } from '@mui/material'
import React, { useSyncExternalStore } from 'react'
import { Line } from 'react-chartjs-2'
import { formateLegend } from '../../../../../../components/features/foodService/dashboard/utils/formateProductsData'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../../helpers/ExternalStore'
import { round2digit } from '../drilldownPromo/PromoInformationsTab'
import { legendMarginPlugin } from './EvolLineChart'
import { MultiAxesGraph } from '../../../../../../redux/promotions/promotionsTypes'

export const MultiAxes = ({
    multiAxes,
    period,
    curveDataName,
    curveEvolName,
}: {
    multiAxes: MultiAxesGraph
    period: string[]
    curveDataName: string
    curveEvolName: string
}) => {
    const formatedLegend = formateLegend(period)

    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.005,
        borderWidth: height * 0.004,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                ticks: {
                    font: { size: height * 0.012 },
                },
            },
            y: {
                ticks: {
                    font: { size: height * 0.012 },
                },
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            y1: {
                ticks: {
                    font: { size: height * 0.012 },
                },
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any, tooltipItem: any) => {
                        const unit = model.dataset.label.includes('€')
                            ? '€'
                            : 'kg'

                        const data = round2digit(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return ` ${model.dataset.label} : ${data} ${unit} `
                    },
                },
            },
        },
    }

    const investmentAndSellInCurveColor =
        curveEvolName === '€ investi par kg' ? '#EF9BFD' : 'purple'

    return (
        <Grid
            sx={{
                height: '36vh',
                padding: '1vw',
            }}
        >
            <Line
                options={options as any}
                data={{
                    labels: [null, ...formatedLegend, null],
                    datasets: [
                        {
                            label: curveEvolName,
                            data: [null, ...multiAxes.evolution, null],
                            borderColor: investmentAndSellInCurveColor,
                            backgroundColor: investmentAndSellInCurveColor,
                            yAxisID: 'y',
                        },
                        {
                            label: curveDataName,
                            data: [null, ...multiAxes.data, null],
                            borderColor: '#3A4D87',
                            backgroundColor: '#3A4D87',

                            yAxisID: 'y1',
                        },
                    ],
                }}
                plugins={legendMarginPlugin}
            />
        </Grid>
    )
}
