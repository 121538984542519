import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../redux/hooks'
import { PromoLayout } from '../layout/PromoLayout'
import { PromoPageV2 } from '../Pages/PromotionPage/page/PromoPageV2'
import { PagesName } from '../../../../components/features/navigation/types/pages'

export const PromoPage = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (

        <PrivatePage period title="Promo" page={PagesName.PROMO}>
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} page={PagesName.PROMO} />
            <PromoLayout filters={activeFsFilters}>
                <PromoPageV2 />
            </PromoLayout>
        </PrivatePage>
    )
}
