import React, { useCallback, useEffect } from "react"
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, NAVBAR } from "./Private/PrivatePage"
import { CardActions, CardContent, Grid, useMediaQuery } from "@mui/material"
import { fetchMultiAccountCompanies, setCompany } from "../../../redux/companies/companiesSlice"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { PrimaryText } from "../../ui/Text"
import { PrimaryButton } from "../../ui/Button"
import { useNavigate } from "react-router-dom"
import Card from '@mui/material/Card';
import { setHeaders } from "../../../api/config"
import { resetActiveFsFilters, resetFSFilters } from "../../../redux/filters/filtersSlice"
import { resetDashboard, setGlobalEnv } from "../../../redux/global/globalSlice"
import { MobileSidebar, Sidebar } from "../navigation/routing/Sidebar"
import { theme } from "../../../pages/private/DataTableComponent"
import PrivateHeader from "./Private/PrivateHeader"
import { Env, EnvName, EnvStatus, EnvType } from "../../../redux/user/userTypes"

const CompanyCard = ({ company }: { company: any }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const _setCompany = useCallback((company) => {
        dispatch(resetDashboard())
        dispatch(resetFSFilters())
        dispatch(resetActiveFsFilters())
        dispatch(setCompany(company))
    }, [dispatch])

    return (<Card sx={{ mb: "3vh", width: "20%", padding: 2 }}>
        <CardContent>
            <PrimaryText>
                {company.name}
            </PrimaryText>
        </CardContent>
        <CardActions>
            <PrimaryButton onClick={() => {
                setHeaders(null, company._id)
                _setCompany(company)
                localStorage.setItem("multiAccountCompanyId", company._id);
                navigate(`/food-service/France Frais/dashboard`)
            }}>
                Accéder à la plateforme
            </PrimaryButton>
        </CardActions>
    </Card>)
}

export const MultiAccountPage = () => {
    const dispatch = useAppDispatch()
    const { multiAccountCompanies } = useAppSelector((state) => state.companies)
    const _fetchMultiAccountCompanies = useCallback(
        async () => await dispatch(fetchMultiAccountCompanies()),
        [dispatch]
    )
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )
    useEffect(() => {
        _setEnv({
            name: EnvName.SUPER_ADMIN,
            type: EnvType.SUPER_ADMIN,
            status: EnvStatus.ACTIVATED
        })
        _fetchMultiAccountCompanies()
    }, [])

    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const contentWidth = isMobile ? '100%' : `calc(88vw - ${NAVBAR}vw)`
    const contentHeight = isMobile
        ? `calc(100% - ${MOBILE_HEADER_HEIGHT}px)`
        : `calc(100% - ${HEADER_HEIGHT}vh)`
    const marginLeft = isMobile ? 0 : `14vw`

    return (
        <Grid height="auto">
            <Grid item>{isMobile ? <MobileSidebar /> : <Sidebar />}</Grid>
            <Grid item sx={{ width: contentWidth, marginLeft: marginLeft }}>
                <PrivateHeader
                    title={""}
                />
                <Grid item sx={{ height: "auto", p: "1vw" }}>
                    <PrimaryText>
                        Sélectionner ci-dessous l’espace fournisseur auquel vous souhaitez accéder
                    </PrimaryText>
                    <Grid container pt="4vh" flexWrap="wrap" justifyContent="space-between">
                        {multiAccountCompanies.map((company) => {
                            return (
                                <CompanyCard company={company} />
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}