import React from 'react'
import { Grid, Tooltip, Box } from '@mui/material'
import PrimaryKpiCard, { KpiType, MockedPrimaryCard } from './PrimaryKpiCard'

interface KpiCardData {
    id: number
    type: KpiType
    name: string
    number: number
    units?: string
    description: string
    difference?: number
    evolution?: number
    route?: string
    icon?: any
}

const Kpis = ({ kpis }: { kpis: KpiCardData[] }) => {

    const kpisCounter = kpis && kpis.length

    return (
        <Grid container justifyContent="space-between">
            {kpis
                ? kpis.map((kpi) =>
                    <Tooltip key={kpi.id} title={kpi.description}>
                        <Box
                            sx={{
                                flex: 1,
                                maxWidth:
                                    kpisCounter === 4
                                        ? '24%'
                                        : kpisCounter === 2
                                            ? '49.5%'
                                            : '32.5%',
                            }}
                        >
                            <PrimaryKpiCard
                                difference={kpi.difference}
                                name={kpi.name}
                                number={kpi.number}
                                units={kpi.units}
                                route={kpi.route}
                                icon={kpi.icon}
                                type={kpi.type}
                                evolution={kpi.evolution} />
                        </Box>
                    </Tooltip>
                )
                : Array(4)
                    .fill('a')
                    .map((item, index) => (
                        <MockedPrimaryCard key={`${item}-${index}`} />
                    ))}
        </Grid>
    )
}

export default Kpis