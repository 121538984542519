import React from "react"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

export const ArrowTendency = ({ number }: { number: number }) => {
    const component =
        number > 0 ? (
            <ArrowUpwardIcon sx={{ height: '1.5vh' }} />
        ) : (
            <ArrowDownwardIcon sx={{ height: '1.5vh' }} />
        )

    return component
}