import React from 'react'
import Label from './DoughnutLabel'
import CheckboxLabels from './MyCheckBoxUpdate'
import Tooltip from '@mui/material/Tooltip'
import { Grid, Typography } from '@mui/material'
import { useAppSelector } from '../../../../../redux/hooks'
import { BLACK } from '../../../../ui/Colors'
import { SquareColor } from '../../../../ui/ColorSquare'
import { Pages } from '../../../../../pages/private/DataTableComponent'
import { EnvType } from '../../../../../redux/user/userTypes'

export const labels = [
    {
        backgroundColor: [
            '#3A4D87',
            '#D4F7A6',
            '#EF9BFD',
            '#F7EAA6',
            '#F7B0A6',
            '#FACE5D',
            '#A17D5B',
            '#E76161',
            '#C9DBB2',
            '#B71375',
        ],
    },
    {
        backgroundColor: [
            '#B9C7F9',
            '#32A9FF',
            '#9EF2ED',
            '#0F52FF',
            '#A17D5B',
            '#192C70',
        ],
    },
]

const sortFamily = (data: number[], labels: string, total: number) => {
    const list = []
    data.forEach((elem, index) =>
        list.push({ base: (data[index] / total) * 100, second: labels[index] })
    )

    list.sort((a, b) => {
        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    const newBase = []
    const newSecond = []
    list.forEach((elem, index) => {
        newBase[index] = list[index].base
        newSecond[index] = list[index].second
    })

    return {
        data: newBase,
        labels: newSecond,
    }
}

const DoughnutLabels = ({
    chart,
    setFieldSelected,
    setOpen,
}: {
    chart: any
    setFieldSelected?: (field: any) => void
    setOpen?: (open: boolean) => void
}) => {
    const { env } = useAppSelector((state) => state.global)
    let clients = null
    if (window.location.href.includes("promo")) {
        clients = useAppSelector((state) => state.promotions.performance.chart)
    } else {
        clients = env.type === EnvType.FOOD_SERVICE
            ? useAppSelector((state) => state.global.fsDashboardData.clients)
            : useAppSelector((state) => state.global.fsCrossDashboardData.clients)
    }

    const sortedValues = sortFamily(
        clients.data,
        clients.labels as any,
        clients.data.reduce((acc, curr) => acc + curr, 0)
    )

    return (
        <Grid container sx={{ maxWidth: '90%' }}>
            <Grid container direction="column" key={0}>
                <CheckboxLabels label={'Type'} page={Pages.DASHBOARD} />
                <Grid container mt={1}>
                    {sortedValues.labels.map((label, labelIndex) => {
                        if (labelIndex > 9) return undefined

                        const index = clients.labels.findIndex(
                            (el) => el === label
                        )

                        return (
                            <Label
                                key={label}
                                clickHandler={() => {

                                    if (env.type === EnvType.FOOD_SERVICE_CROSS || window.location.href.includes("promo")) { return null }

                                    setOpen(true)
                                    setFieldSelected({
                                        id: label,
                                        row: {
                                            volume: clients.data[index],
                                            volumeLastYear:
                                                clients.dataLastYear[index],
                                            clients: clients.clients[index],
                                            products: clients.products[index],
                                            productsLastYear:
                                                clients.productsLastYear[index],
                                            differenceWeight:
                                                clients.differenceWeight[index],
                                            differenceClients:
                                                clients.differenceClients[
                                                index
                                                ],
                                            differenceProducts:
                                                clients.differenceClients[
                                                index
                                                ],
                                            warehouses:
                                                clients.warehouses[index],
                                            warehousesLastYear:
                                                clients.warehousesLastYear[
                                                index
                                                ],
                                            VMM: clients.VMM[index],
                                            VMMLastYear:
                                                clients.VMMLastYear[index],
                                            differenceVMM:
                                                clients.differenceVMM[index],
                                        },
                                    })
                                }}
                                label={
                                    label +
                                    ' : ' +
                                    Math.round(sortedValues.data[labelIndex]) +
                                    '%'
                                }
                                color={labels[0].backgroundColor[labelIndex]}
                                size={SquareColor.SM}
                            />
                        )
                    })}
                    {sortedValues.labels.length > 10 ? (
                        <Tooltip
                            title={
                                <Grid container direction="column">
                                    {sortedValues.labels
                                        .slice(10, sortedValues.labels.length)
                                        .map((label, labelIndex) => (
                                            <span>
                                                {`${label} `}
                                                {'('}
                                                {Math.round(
                                                    sortedValues.data[
                                                    labelIndex
                                                    ]
                                                )}
                                                {labelIndex ===
                                                    sortedValues.labels.length -
                                                    10 -
                                                    1
                                                    ? '%) '
                                                    : '%), '}
                                            </span>
                                        ))}
                                </Grid>
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.2vmin',
                                    color: BLACK,
                                    mt: '0.5vh',
                                    mb: '0.5vh',
                                    ml: '2vw',
                                    textDecoration: 'underline',
                                }}
                            >{`Voir ${sortedValues.labels.length - 10
                                } de +`}</Typography>
                        </Tooltip>
                    ) : undefined}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DoughnutLabels
