import React, { useEffect, useState } from "react"
import Joyride, { CallBackProps } from "react-joyride"
import { PRIMARY } from "../../ui/Colors";
import { addDistributorSteps } from "./steps/dashboardEnvSelectorSteps";


enum Steps {
    ADDDISTRIBUTOR,
}

const AddDistributorsProductTour = ({ setHasProductTour }: { setHasProductTour: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [{ stepIndex, run, steps, type }, setProductTour] = useState({ stepIndex: 0, run: false, steps: addDistributorSteps, type: Steps.ADDDISTRIBUTOR })

    const handleNextStepClick = () => {
        setProductTour((data) => {
            if (data.stepIndex === 1) return { stepIndex: 2, run: false, steps: data.steps, type: data.type }
            else if (data.stepIndex === 2) return { stepIndex: 3, run: false, steps: data.steps, type: data.type }
            else return { stepIndex, run, steps: addDistributorSteps, type }
        })

        setTimeout(() => {
            setProductTour(data => ({ run: true, stepIndex: data.stepIndex, steps: addDistributorSteps, type: data.type }));
        }, 400);
    }

    useEffect(() => {
        if (!localStorage.getItem('addDistributorProductTour')) {
            setProductTour(data => ({ run: true, stepIndex, steps, type }));
        }
    }, [])

    useEffect(() => {
        handleNextStepClick()
        const menu = document.getElementById("env-selector")
        if (menu) menu.addEventListener('click', handleNextStepClick)
    }, [window.location.pathname])

    const handleProductTourCallback = (data: CallBackProps) => {
        const {
            action,
            index,
            step: {
                data: { next, previous },
            },
            type: productType,
        } = data;
        const isPreviousAction = action === 'prev';

        if (productType === "tour:end") {
            localStorage.setItem('addDistributorProductTour', 'true')
            setHasProductTour(false)
            const menu = document.getElementById("env-selector")
            if (menu) menu.click()
        }

        if (productType === 'step:after') {
            if (index != 1 || type != Steps.ADDDISTRIBUTOR) setProductTour((data) => {
                return ({ stepIndex: data.stepIndex + 1, run: true, steps, type: data.type })
            })

        }
    };

    return <Joyride
        callback={handleProductTourCallback}
        continuous
        run={run}
        disableScrolling
        scrollToFirstStep
        hideBackButton
        showProgress
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
            overlay: { height: "120vh" },
            options: {
                zIndex: 10000,
                primaryColor: PRIMARY
            },
        }} />
}

export default AddDistributorsProductTour