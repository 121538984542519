import React from 'react'
import { DARK_GREY, GREEN, ORANGE } from '../../ui/Colors'
import { Grid, Tooltip } from '@mui/material'
import { ConnectorType, EnvStatus } from '../../../redux/user/userTypes'
import { LastImport } from '../../../redux/imports/importsTypes'
import { SpecificChip } from '../../ui/SpecificChip'

const lastUpdateText = (last: LastImport[], name: string) => {
    const lastImportDate = last.length > 0 ? last[0].created_at : ''
    const updateDate = new Date(lastImportDate).toLocaleDateString('fr-FR')

    const textToDisplay =
        last.length > 0 ? ` Dernière mise à jour le ${updateDate}.` : ''
    return textToDisplay
}

const colorStatus = {
    [EnvStatus.ACTIVATED]: GREEN,
    [EnvStatus.INACTIVATED]: ORANGE,
}

const labels = {
    [ConnectorType.AUTOMATED]: {
        dataUpdatedLabel: 'Connecteur actif',
        updateDataLabel: 'Connecter',
    },
    [ConnectorType.MANUAL]: {
        dataUpdatedLabel: 'Données à jour',
        updateDataLabel: 'Importer données',
    },
}

export const EnvStatusChip = ({
    status,
    pointer,
    name,
    last = [],
    connectorType,
}: {
    status: EnvStatus
    pointer: boolean
    name?: string
    last?: LastImport[] | []
    connectorType?: ConnectorType
}) => {
    switch (status) {
        case EnvStatus.ACTIVATED:
            return (
                <Tooltip
                    title={
                        <span>
                            Vos données sont actualisées.
                            <br />
                            {lastUpdateText(last, name)}
                        </span>
                    }
                >
                    <Grid>
                        <SpecificChip
                            pointer={pointer}
                            label={labels[connectorType].dataUpdatedLabel}
                            color={colorStatus[status]}
                        />
                    </Grid>
                </Tooltip>
            )
        case EnvStatus.OUTDATED:
            return (
                <Tooltip
                    title={`Importez vos dernières données ${name}.                         
                           ${lastUpdateText(last, name)}
                           `}
                >
                    <Grid>
                        <SpecificChip
                            pointer={pointer}
                            label="Importer"
                            activable
                            color={ORANGE}
                        />
                    </Grid>
                </Tooltip>
            )
        case EnvStatus.ON_LOADING_INIT:
        case EnvStatus.ON_LOADING:
            return (
                <Tooltip
                    title={
                        <span>
                            Vos données sont en cours de traitement.
                            <br />
                            {lastUpdateText(last, name)}
                        </span>
                    }
                >
                    <Grid>
                        <SpecificChip
                            pointer={pointer}
                            label="En cours"
                            color={DARK_GREY}
                        />
                    </Grid>
                </Tooltip>
            )
        default:
            return (
                <Tooltip
                    title={
                        'Pour ajouter un distributeur, intégrez de nouvelles données sell-out.'
                    }
                >
                    <Grid>
                        <SpecificChip
                            pointer={pointer}
                            label={labels[connectorType].updateDataLabel}
                            activable
                            color={colorStatus[status]}
                        />
                    </Grid>
                </Tooltip>
            )
    }
}
