import React from "react"
import { Grid } from "@mui/material"
import { ChipCard } from "../../../../../../components/features/ChipCard"
import { Units } from "../../../../../../redux/global/globalTypes"
import { Campaign } from "../../../../../../redux/views/viewsTypes"

export const TopFlopCampaigns = ({ campaigns }: { campaigns: Campaign[] }) => {
    const positionOfLastCampaign = campaigns.length - 1
    const worstCampaign = campaigns[0]
    const bestCampaign = campaigns[positionOfLastCampaign]

    return (<Grid container flexWrap="wrap" ml="2vw" mb="2vh" >
        <ChipCard productName={worstCampaign.name} number={worstCampaign.data.incremental} isTop={false} unit={Units.kg} />
        <ChipCard productName={bestCampaign.name} number={bestCampaign.data.incremental} isTop={true} unit={Units.kg} />
    </Grid>)
}