import { Box, ThemeProvider } from '@mui/material'
import React, { useSyncExternalStore } from 'react'
import { DataGrid, GridCellParams } from '@mui/x-data-grid'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../helpers/ExternalStore'
import { DataTableComponentProps, theme } from './DataTableComponent'

export const DataTableComponentWithoutHeader = ({
    rows,
    columns,
    getRowId,
    setFieldSelected,
    setOpen,
    hideFooter = false,
}: DataTableComponentProps) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    justifyContent: 'center !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer:first-child': {
                    justifyContent: 'center !important',
                },
                '& div.MuiDataGrid-columnHeader:nth-child(1) > div:nth-child(1) > div:nth-child(1)':
                {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft:first-child': {
                    justifyContent: 'flex-start !important',
                },
                '& .MuiDataGrid-cell--textLeft': {
                    justifyContent: 'center !important',
                },
            }}
        >
            <ThemeProvider theme={theme}>
                <DataGrid
                    onCellClick={(row) => {
                        {
                            if (row.id !== 'TOTAUX AUTRES PRODUITS') {
                                setOpen(true)
                                setFieldSelected(row)
                            }
                        }
                    }}
                    hideFooterSelectedRowCount={true}
                    rows={rows}
                    columns={columns}
                    getRowId={getRowId}
                    rowHeight={height * 0.035}
                    hideFooter={hideFooter}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        background: 'white',
                    }}
                    headerHeight={0}
                    components={{
                        Header: () => null,
                    }}
                    getCellClassName={(
                        params: GridCellParams<any, any, number>
                    ) => {
                        if (params.id === 'TOTAUX AUTRES PRODUITS') {
                            return 'PromoPageFirstLine'
                        }
                    }}
                />
            </ThemeProvider>
        </Box>
    )
}
