import { Chip, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import {
    EnvName,
    EnvType,
    type Products,
} from '../../../../redux/user/userTypes'
import { BLACK, BRIC, LIGHT_MOCK, SECONDARY } from '../../../ui/Colors'
import { Annotation, PrimaryText } from '../../../ui/Text'
import NumberWithSpace from '../../../ui/NumberWithSpace'
import { Units } from '../../../../redux/global/globalTypes'

interface OwnProps {
    data: Products
    isTop: boolean
    border?: boolean
}

interface InfoListProps {
    references: number
    vmm: number
    clients: number
    family: string
}

interface InfoListItemProps {
    title: string
    data: number
    unit: string
    tooltip: string
}

const GREEN = '#809861'
const RED = '#E36F20'

const ChipIndicator = ({ isTop }: { isTop: boolean }) => {
    return (
        <Chip
            label={isTop ? 'MAX' : 'MIN'}
            sx={{
                background: isTop ? SECONDARY : BRIC,
                width: '2.6vw',
                height: '1.6vh',
                fontSize: '0.9vmin',
                mb: '1vh',
                cursor: "default",
                '& span': {
                    paddingLeft: '0.5vw',
                    paddingRight: '0.5vw',
                },
            }}
        />
    )
}

const InformationListItem = ({
    title,
    data,
    unit,
    tooltip,
}: InfoListItemProps) => {
    return (
        <Tooltip title={tooltip}>
            <Grid item>
                <Annotation color={BLACK}>{title}</Annotation>
                <Annotation color={BLACK} bold>
                    <NumberWithSpace number={data} /> {unit}
                </Annotation>
            </Grid>
        </Tooltip>
    )
}

const InformationsList = ({
    references,
    vmm,
    clients,
    family,
}: InfoListProps) => {
    const { unit } = useAppSelector((state) => state.global)
    const envName = useAppSelector((state) => state.global.env.name)

    return (
        <Grid container direction="column">
            <InformationListItem
                title="Nombre de références"
                data={references}
                unit="références"
                tooltip={`Nombre total de références dans votre famille ${family}.`}
            />
            <InformationListItem
                title="VMM"
                data={Math.round(vmm)}
                unit={unit}
                tooltip={`Volume moyen vendu mensuellement de votre famille ${family}.`}
            />
            {envName === EnvName.FRANCE_FRAIS && (
                <InformationListItem
                    title="Utilisateurs finaux"
                    data={clients}
                    unit="clients"
                    tooltip={`Nombre total d'utilisateurs finaux ayant acheté au moins une référence dans votre famille ${family}.`}
                />
            )}
        </Grid>
    )
}

const LineChartTitle = ({ title }: { title: string }) => {
    if (title && title.length > 28) {
        return (
            <Tooltip title={title}>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        color: BLACK,
                        whiteSpace: 'nowrap',
                        maxWidth: '90%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '1.6vmin',
                        cursor: "default"
                    }}
                >
                    {title}
                </Typography>
            </Tooltip>
        )
    }

    return (
        <PrimaryText
            bold
            color={BLACK}
            whiteSpace="nowrap"
            maxWidth="90%"
            overflow="hidden"
            textOverflow="ellipsis"
        >
            {title}
        </PrimaryText>
    )
}

const LineChartCard = ({ data, isTop, border = true }: OwnProps) => {
    const { unit } = useAppSelector((state) => state.global)

    const title = isTop
        ? 'Croissance maximale enregistrée par rapport à l’année précédente sur la même période.'
        : "Recul maximal (ou croissance minimale) enregistré par rapport à l'année précédente sur la même période."

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            sx={{
                borderRight: border ? `1px solid ${LIGHT_MOCK}` : 'none',
                height: '50%',
            }}
        >
            <Grid
                container
                direction="column"
                flex={2}
                sx={{ maxWidth: '66%', pl: '0.5vw' }}
            >
                {border && <ChipIndicator isTop={isTop} />}
                <LineChartTitle title={data._id} />
                <Tooltip title={title}>
                    <Typography
                        sx={{
                            fontSize: '2.8vmin',
                            color: data.differenceWeight > 0 ? GREEN : RED,
                            fontWeight: 'bold',
                            cursor: "default"
                        }}
                    >
                        {data.differenceWeight > 0 ? '+ ' : null}
                        <NumberWithSpace
                            number={Math.round(data.differenceWeight)}
                        />
                        {unit}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item flex={1}>
                <InformationsList
                    references={data.products}
                    vmm={data.vmm}
                    clients={data.clients}
                    family={data._id}
                />
            </Grid>
        </Grid>
    )
}

export default LineChartCard
