import React, { useState } from 'react'
import { GridCellParams } from '@mui/x-data-grid'
import { DataTableComponentWithoutHeader } from '../../../../DataTableComponentWithoutHeader'
import { columnsProductsListPromoPage } from './ProductsList'
import DrillDown from '../../../../../../components/features/drillDowns/DrillDown'
import { Grid } from '@mui/material'
import { DrillDownType } from '../../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { Product } from '../../../../../../redux/promotions/promotionsTypes'

export const ProductsListOthers = ({
    productsList,
    drillDownType,
}: {
    productsList: Product[]
    drillDownType: DrillDownType
}) => {
    const [isOpen, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] = useState<GridCellParams<any>>()
    const [value, setValue] = useState(0)

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Grid
            item
            mt="1vw"
            sx={{
                height: '30vh',
                '& .PromoPageFirstLine': {
                    backgroundColor: '#CFFF97',
                    cursor: 'auto',
                },
            }}
        >
            <DrillDown
                type={drillDownType}
                open={isOpen}
                title={fieldSelected && `${fieldSelected.row.name}`}
                value={value}
                setValue={setValue}
                row={fieldSelected}
                handleClose={handleClose}
            />
            <DataTableComponentWithoutHeader
                type={drillDownType}
                setOpen={setOpen}
                setFieldSelected={setFieldSelected}
                setValue={setValue}
                rows={productsList}
                columns={columnsProductsListPromoPage()}
                getRowId={(row) => row.id}
                hideFooter={true}
            />
        </Grid>
    )
}
