import React, { useEffect, useState } from 'react'
import { Box, Grid, Popover, TextField, styled } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { BLACK, GREY, MEDIUM_GREY, PRIMARY, RED, WHITE } from '../../ui/Colors'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ErrorMessage from '../../ui/ErrorMessage'
import { PrimaryText } from '../../ui/Text'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { PrimaryButton } from '../../ui/Button'
import { useAppSelector } from '../../../redux/hooks'
import { EnvType } from '../../../redux/user/userTypes'
import {
    borderFilter,
    borderFilterActive,
    borderFilterSelected,
} from './selects/MainFilter'
import { useLocation } from 'react-router-dom'

interface OwnProps {
    start: Date
    end: Date
    setFieldValue: (field: string, value: any) => void
    errorEndDate: string
    errorStartDate: string
    placeholder: string
    submitPeriod?: () => void
    isDialogFilter?: boolean
    isModified?: boolean
    setModified?: (modified: boolean) => void
    env?: EnvType
}

interface CalendarInputProps {
    handleClick: (e) => void
    open: boolean
    error: boolean
    placeholder: string
    isModified?: boolean
    borderCSS?: string
}

interface CalendarPopoverProps {
    open: boolean
    onClose: () => void
    anchorEl: null | HTMLElement
    setFieldValue: (field: string, value: any) => void
    start: Date
    end: Date
    errorEndDate: string
    errorStartDate: string
    isDialogFilter?: boolean
    submitPeriod: () => void
    setModified?: (modified: boolean) => void
    env?: EnvType

}

interface CalendarDatePickerProps {
    min: Date
    max: Date
    setFieldValue: (field: string, value: any) => void
    name: string
    value: Date
    setModified?: (modified: boolean) => void
}

const CalendarDatePicker = ({
    min,
    max,
    setFieldValue,
    name,
    value,
    setModified = null,
}: CalendarDatePickerProps) => {
    const nonAvailableDates = useAppSelector((state) => state.filters.nonAvailableDates)

    const shouldDisableMonth = (month: Dayjs) => {
        const formattedMonth = month.format('YYYY-MM') // Format correct pour la comparaison

        return nonAvailableDates.includes(formattedMonth)
    }

    return (
        <DatePicker
            sx={{ "& input": { fontSize: { xs: '16px', md: '1.7vmin' }, padding: "1vw 2vh" } }}
            maxDate={dayjs(max)}
            minDate={dayjs(min)}
            views={['year', 'month']}
            label="Mois et Année"
            value={dayjs(value)}
            onChange={(newDate) => {
                if (newDate) {
                    const timedDate = newDate.toDate()
                    timedDate.setHours(0, 0, 0)
                    setFieldValue(name, timedDate)
                    if (setModified !== null) {
                        setModified(true)
                    }
                } else {
                    setFieldValue(name, '')
                }
            }}
            shouldDisableMonth={shouldDisableMonth}
            slotProps={{
                layout: {
                    sx: {
                        '& .MuiDateCalendar-root': {
                            height: 'auto',
                        },
                    },
                },
            }}
        />
    )
}

const SpacedButton = styled(PrimaryButton)`
    width: 10vw;
    margin-bottom: 2vh; 
    padding: 0.5vh
`;

export const CalendarPopover = ({
    open,
    onClose,
    anchorEl,
    setFieldValue,
    start,
    end,
    errorEndDate,
    errorStartDate,
    env,
    setModified,
    submitPeriod,
    isDialogFilter = false,
}: CalendarPopoverProps) => {
    const [lastQuarter, setLastQuarter] = useState<{ start: Date, end: Date }>(null)
    const [currentYear, setCurrentYear] = useState<{ start: Date, end: Date }>(null)
    const envName = env ? env : useAppSelector((state) => state.global.env.type)

    // Check for view page
    const { pathname } = useLocation()
    const isViewPage = pathname.indexOf("vues") >= 0

    const selectedFilters = (envName: EnvType) => {
        switch (envName) {
            case EnvType.FOOD_SERVICE:
                return isViewPage ? "fsViewFilters" : "fsFilters"
            case EnvType.RETAIl:
                return isViewPage ? "retailViewFilters" : "retailFilters"
            case EnvType.FOOD_SERVICE_CROSS:
                return isViewPage ? "fsCrossViewFilters" : "fsCrossFilters"
        }
    }

    const { min, max } = useAppSelector(
        (state) => state.filters[selectedFilters(envName)]
    )

    const minDate = new Date(min)
    const maxDate = new Date(max)
    const hasQuarterBtn = maxDate.getMonth() - minDate.getMonth() > 2

    useEffect(() => {
        // Handle last quarter btn
        const endLastQuarter = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
        let startLastQuarter = null
        if (endLastQuarter.getMonth() < 2) {
            startLastQuarter = new Date(endLastQuarter.getFullYear() - 1, 11 - endLastQuarter.getMonth(), 0);
        } else {
            startLastQuarter = new Date(endLastQuarter.getFullYear(), endLastQuarter.getMonth() - 1, 0);
        }
        setLastQuarter({ start: startLastQuarter, end: endLastQuarter })

        // Handle current year
        const endOfYear = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1)
        let startOfYear = null
        if (minDate.getFullYear() != maxDate.getFullYear()) {
            startOfYear = new Date(maxDate.getFullYear(), 0, 1)
        } else {
            startOfYear = new Date(minDate.getFullYear(), minDate.getMonth(), 1)
        }
        setCurrentYear({ start: startOfYear, end: endOfYear })
    }, [])

    const buttonIsDisabled = (errorStartDate && errorStartDate.length > 1) || (errorEndDate && errorEndDate.length > 1)

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
                !isDialogFilter && submitPeriod()
                onClose()
            }
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{
                display: "flex",
                mt: 1,
            }}
        >
            <Grid container>
                <Grid width="12vw" padding={4} gap={8}>
                    <SpacedButton onClick={() => {
                        setFieldValue("startDate", currentYear.start)
                        setFieldValue("endDate", currentYear.end)
                    }}>Année en cours</SpacedButton>
                    {hasQuarterBtn && <SpacedButton onClick={() => {
                        setFieldValue("startDate", lastQuarter.start)
                        setFieldValue("endDate", lastQuarter.end)
                    }}>3 derniers mois</SpacedButton>}
                </Grid>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{
                        borderRadius: '4px',
                        border: 'none',
                        background: WHITE,
                        p: 3,
                        height: '100%',
                        width: '17.5vw',
                        padding: '1vw',
                        paddingBottom: '0',
                    }}
                >
                    <Grid item p={'0.5vw'}>
                        <LocalizationProvider
                            adapterLocale="fr"
                            dateAdapter={AdapterDayjs}
                        >
                            <Grid
                                container
                                direction="column"
                                height="100%"
                                wrap="nowrap"
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    flex={1}
                                    wrap="nowrap"
                                >
                                    <PrimaryText color={BLACK} width="3vw" mr="1vw">
                                        De
                                    </PrimaryText>
                                    <CalendarDatePicker
                                        setModified={setModified}
                                        min={minDate}
                                        max={maxDate}
                                        setFieldValue={setFieldValue}
                                        name="startDate"
                                        value={start}
                                    />
                                </Grid>
                                {errorStartDate && (
                                    <Grid pl="2.2vw">
                                        <ErrorMessage msg={errorStartDate} />
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    alignItems="center"
                                    mt={4}
                                    flex={1}
                                    wrap="nowrap"
                                >
                                    <PrimaryText color={BLACK} width="3vw" mr="1vw">
                                        À
                                    </PrimaryText>
                                    <CalendarDatePicker
                                        setModified={setModified}
                                        min={minDate}
                                        max={maxDate}
                                        setFieldValue={setFieldValue}
                                        name="endDate"
                                        value={end}
                                    />
                                </Grid>
                                {errorEndDate && (
                                    <Grid pl="2.2vw">
                                        <ErrorMessage msg={errorEndDate} />
                                    </Grid>
                                )}
                            </Grid>
                        </LocalizationProvider>
                    </Grid>

                </Grid>
            </Grid>
            {isDialogFilter ? <PrimaryButton
                sx={{
                    width: '100%',
                    backgroundColor: `${WHITE} !important`,
                    borderWidth: '0.2vh',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    opacity: 1,
                    fontSize: { xs: '16px', md: '1.5vmin' },
                }}
                onClick={onClose}
            >
                Fermer
            </PrimaryButton> : <PrimaryButton
                sx={{
                    width: '100%',
                    backgroundColor: buttonIsDisabled ? `${GREY}` : `${PRIMARY} !important`,
                    color: "white !important",
                    borderWidth: '0.2vh',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    opacity: 1,
                    fontSize: { xs: '16px', md: '1.5vmin' },
                }}
                disabled={buttonIsDisabled}
                onClick={() => {
                    submitPeriod()
                    onClose()
                }}
            >
                Filtrer
            </PrimaryButton>}
        </Popover>
    )
}

const CalendarInput = ({
    handleClick,
    open,
    error,
    placeholder,
    isModified,
    borderCSS,
}: CalendarInputProps) => {
    return (
        <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            wrap="nowrap"
            sx={{
                flex: 1,
                background: WHITE,
                height: '3.5vh',
                '&:last-child': { mr: 0 },
                p: '0 8px 0 14px',
                borderRadius: '4px',
                border: error ? `1px solid ${RED}` : borderCSS,
                cursor: 'pointer',
            }}
            onClick={(e) => {
                handleClick(e)
            }}
        >
            <Grid container alignItems="center" flexWrap={'nowrap'}>
                <Box
                    sx={{
                        color: MEDIUM_GREY,
                        mr: 2,
                        '& svg': {
                            width: '2vw',
                            height: '2vh',
                            mt: '0.8vh',
                        },
                    }}
                >
                    <CalendarMonthIcon />
                </Box>
                <PrimaryText
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    color={BLACK}
                >
                    {placeholder ? placeholder : 'Mois et année'}
                </PrimaryText>
            </Grid>

            {open ? (
                <ArrowDropUpIcon sx={{ color: '#858585' }} />
            ) : (
                <ArrowDropDownIcon sx={{ color: '#858585' }} />
            )}
        </Grid>
    )
}

const FilterCalendar = ({
    setFieldValue,
    start,
    end,
    env,
    errorEndDate,
    errorStartDate,
    placeholder,
    isModified,
    setModified,
    submitPeriod,
    isDialogFilter = false
}: OwnProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const [selectBorder, setSelectBorder] = useState(borderFilter)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget)
        setOpen(true)
        setSelectBorder(borderFilterActive)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)

        if (isModified) {
            setSelectBorder(borderFilterSelected)
        } else {
            setSelectBorder(borderFilter)
        }
    }

    return (
        <Grid container sx={{ flex: 1, mr: 2 }}>
            <CalendarInput
                isModified={isModified}
                placeholder={placeholder}
                handleClick={handleClick}
                open={open}
                error={!!errorStartDate && !!errorEndDate}
                borderCSS={selectBorder}
            />
            <CalendarPopover
                isDialogFilter={isDialogFilter}
                submitPeriod={submitPeriod}
                setModified={setModified}
                setFieldValue={setFieldValue}
                start={start}
                end={end}
                errorEndDate={errorEndDate}
                errorStartDate={errorStartDate}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                env={env}
            />
        </Grid>
    )
}

export default FilterCalendar
