import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { ProductTourDialog } from '../../../../components/features/ProductTourDialog'
import { fetchProductsTour } from '../../../../redux/product-tour/productsTourSlice'
import {
    EnvName,
    FsTGMKPIs,
    UsersStatus,
} from '../../../../redux/user/userTypes'
import { DashboardFSPage } from '../Pages/DashboardFSPage'
import { TransgourmetColumnsDashboard } from '../utils'
import { Units } from '../../../../redux/global/globalTypes'
import { ConfigKPIs } from '../Pages/Dashboard/FsKpis'
import { DashboardLayout } from '../layout/DashboardLayout'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const KPIsTGMConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsTGMKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Total des volumes facturés sur la période sélectionnée et variation par rapport à l’année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
        {
            id: 3,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de points de vente finaux ayant distribué vos références sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionWarehouses
        },
    ]

export const DashboardTGM = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { productTour } = useAppSelector((state) => state.productsTour)
    const { status } = useAppSelector((state) => state.user.user)
    const { unit } = useAppSelector((state) => state.global)
    const dispatch = useAppDispatch()
    const _fetchProductsTour = useCallback(async () => {
        await dispatch(fetchProductsTour())
    }, [dispatch])

    useEffect(() => {
        _fetchProductsTour()
    }, [])

    return (
        <PrivatePage
            title="Tableau de bord"
            period={status != UsersStatus.STANDALONE}
        >
            {status != UsersStatus.STANDALONE && (
                <Filters
                    filtersType={FiltersType.FS}
                    filters={activeFsFilters}
                />
            )}
            <DashboardLayout>
                <DashboardFSPage
                    KPIsConfig={KPIsTGMConfig}
                    warehousesConfig={TransgourmetColumnsDashboard(unit)}
                    drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSESTGM}
                    drilldownTypeClientSegmentation={DrillDownType.CLIENTSTGM}
                    dashboardGridDataTitle={`Top 3 des dépôts en part du chiffre d’affaires`}
                    segmentationChartTitle={`Segmentation utilisateurs par volume (${unit})`}
                />
            </DashboardLayout>
            {productTour && (
                <ProductTourDialog
                    title={productTour.title}
                    productTourId={productTour._id}
                />
            )}
        </PrivatePage>
    )
}
