import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid"
import { Env, EnvName, EnvStatus, UsersRole, UsersStatus } from "../../../../redux/user/userTypes"
import { PrimaryText, SecondaryText, SubTitle } from "../../../../components/ui/Text"
import { BLACK, BRIC, PRIMARY } from "../../../../components/ui/Colors"
import React from "react"
import { SpecificChip } from "../../../../components/ui/SpecificChip"
import { Grid } from "@mui/material"

const statusToDisplay = (status: UsersStatus): string => {
    switch (status) {
        case UsersStatus.ACTIVATED:
            return "Actif"
        case UsersStatus.PENDING:
            return "En attente de création de compte"
        case UsersStatus.DEACTIVATED:
            return "Désactivé"
        default:
            return "Actif"
    }
}

const roleToDisplay = (role: UsersRole): string => {
    switch (role) {
        case UsersRole.ADMIN:
            return "Admin"
        case UsersRole.USER:
            return "Utilisateur"
        default:
            return "Utilisateur"
    }
}

export const MembersDataGridColumns = (): GridColDef[] => {
    return [
        {
            field: 'firstname',
            headerName: 'Collaborateur',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {

                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {

                const collaborator = params.row.status !== UsersStatus.PENDING ? `${params.row.firstname} ${params.row.lastname}` : `${params.row.email}`
                return (
                    <SecondaryText style={{ fontSize: "1.7vmin" }} color={BLACK}>{collaborator}</SecondaryText>
                )
            },
        }, {
            field: 'role',
            headerName: 'Rôle',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const color = params.row.role === UsersRole.ADMIN ? "#6B95FF" : BLACK

                return (
                    <SecondaryText style={{ fontSize: "1.7vmin" }} color={color}>{roleToDisplay(params.value)}</SecondaryText>
                )
            },
        },
        {
            field: 'env',
            headerName: 'Espaces à disposition',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container
                        p={2}
                        sx={{
                            justifyContent: 'flex-start'
                        }}>
                        {getEnvNames(params.row.env).map((el) => {
                            return (
                                <Grid p={1}>
                                    <SpecificChip
                                        pointer={false}
                                        label={el}
                                        color={PRIMARY}
                                    />
                                </Grid>)
                        })}
                    </Grid>

                )
            },
        },
        {
            field: 'status',
            headerName: 'Statut',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const color = params.row.status === UsersStatus.PENDING ? BRIC : BLACK

                return (
                    <SecondaryText style={{ fontSize: "1.7vmin" }} color={color}>{statusToDisplay(params.value)}</SecondaryText>
                )
            },
        },
        // {
        //     field: 'action',
        //     headerName: 'Actions',
        //     flex: 2,
        //     editable: false,
        //     renderHeader: (params: GridColumnHeaderParams) => {
        //         return (
        //             <PrimaryText color={BLACK}>
        //                 {params.colDef.headerName}
        //             </PrimaryText>
        //         )
        //     },
        //     renderCell: (params: GridRenderCellParams) => {
        //         return (
        //             <EditIcon sx={{ color: BLACK, cursor: 'pointer', }} />
        //         )
        //     },
        // },
    ]
}

export const getEnvNames = (list: Env[]) => {
    return list.filter((el) => el.status === EnvStatus.ACTIVATED && el.name !== EnvName.SETTING).map((env) => env.name)
}

export const roles = [
    { label: 'Utilisateur', value: UsersRole.USER },
    {
        label: `Administrateur : peut gérer les utilisateurs de l'entreprise et inviter d’autres membres à rejoindre l’espace KaryonFood.`,
        value: UsersRole.ADMIN,
    },
]