import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchProductsTour } from '../../../../redux/product-tour/productsTourSlice'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import {
    EnvName,
    FsSodexoKPIs,
    UsersStatus,
} from '../../../../redux/user/userTypes'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { DashboardLayout } from '../layout/DashboardLayout'
import { DashboardFSPage } from '../Pages/DashboardFSPage'
import { SodexoColumnsDashboard } from '../utils'
import { ProductTourDialog } from '../../../../components/features/ProductTourDialog'
import { ConfigKPIs } from '../Pages/Dashboard/FsKpis'
import { Units } from '../../../../redux/global/globalTypes'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'

export const KPIsSodexoConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsSodexoKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Volume total sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
    ]


const DashboardSodexo = () => {

    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { productTour } = useAppSelector((state) => state.productsTour)
    const { status } = useAppSelector((state) => state.user.user)
    const { unit } = useAppSelector((state) => state.global)
    const dispatch = useAppDispatch()
    const _fetchProductsTour = useCallback(async () => {
        await dispatch(fetchProductsTour())
    }, [dispatch])

    useEffect(() => {
        _fetchProductsTour()

    }, [])

    return (
        <PrivatePage
            title="Tableau de bord"
            period={status != UsersStatus.STANDALONE}
        >

            {status != UsersStatus.STANDALONE && (
                <Filters
                    filtersType={FiltersType.FS}
                    filters={activeFsFilters}
                />
            )}
            <DashboardLayout>
                <DashboardFSPage
                    KPIsConfig={KPIsSodexoConfig}
                    warehousesConfig={SodexoColumnsDashboard(Units.kg)}
                    hasWarehouseDrilldown={false}
                    drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                    drilldownTypeClientSegmentation={
                        DrillDownType.CLIENTSSODEXO
                    }
                    dashboardGridDataTitle={`Volume livré par type de marché (${unit})`}
                    segmentationChartTitle={`Segmentation utilisateurs par volume (${unit})`}
                />
            </DashboardLayout>
            {productTour && (
                <ProductTourDialog
                    title={productTour.title}
                    productTourId={productTour._id}
                />
            )}
        </PrivatePage>
    )
}

export default DashboardSodexo
