import React, { useCallback } from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { Navigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { FsViewFilters } from '../../../../redux/filters/filtersTypes'
import Loading from '../../../../components/features/screens/LoadingScreen'
import { PromoPageV2 } from '../Pages/PromotionPage/page/PromoPageV2'
import { PromoLayout } from '../layout/PromoLayout'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { setActiveFsFilters } from '../../../../redux/filters/filtersSlice'
import { setIsFiltered } from '../../../../redux/global/globalSlice'

export const CampaignPage = () => {
    const dispatch = useAppDispatch()
    const { campaignId } = useParams()
    const { campaigns } = useAppSelector((state) => state.views)
    const campaign = campaigns.length > 0 ? campaigns.find((campaign) => campaign._id === campaignId) : null
    const filters = campaign ? campaign.filters as FsViewFilters : null

    if (campaigns.length === 0) {
        return <PrivatePage><Loading /></PrivatePage>
    }

    // Allowed to get the right date on the header
    dispatch(
        setActiveFsFilters({
            startDate: filters.startDate,
            endDate: filters.endDate,
            min: filters.min,
            max: filters.max,
            products: filters.products,
            clients: filters.clients,
            regions: filters.regions ? filters.regions : [],
            categories: filters.categories ? filters.categories : [],
        })
    )
    const _setIsFilteredFS = useCallback(
        (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
        [dispatch]
    )

    _setIsFilteredFS(true)

    if (!campaign) {
        return <Navigate to="/" />
    } else {
        return (
            <PrivatePage title={campaign.name} period page={PagesName.CAMPAIGN}>
                <Filters filtersType={FiltersType.FS} filters={filters} page={PagesName.CAMPAIGN} />
                <PromoLayout filters={filters}>
                    <PromoPageV2 />
                </PromoLayout>
            </PrivatePage>
        )
    }
}
