import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { GREY, WHITE } from '../ui/Colors';
import { Annotation } from '../ui/Text';
import { Box } from '@mui/system';
import { Units } from '../../redux/global/globalTypes';
import { roundIfNotZero } from '../../helpers/roundIfNotZero';
import SquareIcon from '@mui/icons-material/Square';
import { Grid } from '@mui/material';
import NumberWithSpace from '../ui/NumberWithSpace';


const xLabels = [
    'Sell-In',
    'Sell-Out'
];

export enum LegendColor {
    GLOBAL = "#D0DDFF",
    PART = "#A5BEFE",
    SECONDBAR = "#0C50D3"
}

export const StackedBarChart = ({ global, globalLabel, part, partLabel, secondBar, secondBarLabel }: { global: number, globalLabel: string, part: number, partLabel: string, secondBar: number, secondBarLabel: string }) => {
    const unit = Units.kg
    const CustomItemTooltip = () => {
        return <Box sx={{ backgroundColor: "#070C1C", p: "1vh", flexWrap: "nowrap", opacity: "85%", borderRadius: "5px" }} >
            <Grid container >
                <SquareIcon fontSize="small" sx={{ color: "#A5BEFE" }} /><Annotation color={WHITE} bold>Sell-in promo :  <NumberWithSpace number={roundIfNotZero(part)} /> {unit}</Annotation>
            </Grid>
            <Grid container >
                <SquareIcon fontSize="small" sx={{ color: "#D0DDFF" }} /> <Annotation color={WHITE} bold>Sell-in hors promo : <NumberWithSpace number={roundIfNotZero(global - part)} /> {unit}</Annotation> </Grid>
            <Grid container >
                <Grid container >
                    <SquareIcon fontSize="small" sx={{ color: "#D0DDFF" }} /><Annotation color={WHITE} bold>Sell-in global : <NumberWithSpace number={roundIfNotZero(global)} /> {unit}</Annotation>
                </Grid>
                <SquareIcon fontSize="small" sx={{ color: "#0C50D3" }} /> <Annotation color={WHITE} bold>Sell-out : <NumberWithSpace number={roundIfNotZero(secondBar)} />  {unit}</Annotation>
            </Grid>
        </Box>
    }

    return (
        <BarChart
            width={200}
            height={250}
            sx={(theme) => ({
                [`.MuiBarElement-series-l_id`]: {
                    stroke: "purple",
                },
                [`.MuiBarElement-series-r_id`]: {
                    stroke: "red",
                },
                [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                        stroke: GREY,
                        strokeWidth: 1,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                        fill: GREY,
                    },
                },
            })
            }
            series={
                [
                    { data: [part], label: `${partLabel}`, id: '2', stack: 'total', color: LegendColor.PART },
                    { data: [global], label: `${globalLabel}`, id: '1', stack: 'total', color: LegendColor.GLOBAL },
                    { data: [0, secondBar], label: `${secondBarLabel}`, id: '3', stack: 'total', color: LegendColor.SECONDBAR },
                ]}
            tooltip={{
                trigger: "item"
            }}
            slots={{
                itemContent: CustomItemTooltip
            }}
            xAxis={[{ data: xLabels, scaleType: 'band' }]}
            slotProps={{
                legend: {
                    hidden: true,
                },
            }}
        />)
}