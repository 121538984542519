import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Grid, Typography } from '@mui/material'
import { LIGHT_GREY, REGULAR_MOCK } from '../ui/Colors'
import { useNavigate } from 'react-router-dom'
import { KpiCard } from './KpiCard'
import { GaugeCard } from './GaugeCard'

export enum KpiType {
    NUMBER = "number",
    GAUGE = "gauge"
}
export interface KpiCardType {
    name: string
    number: number
    units?: string
    difference?: number
    route?: string
    icon?: any
    type?: KpiType
    evolution?: number
}

export function MockedPrimaryCard() {
    return (
        <Card
            sx={{
                flex: 1,
                mr: 2,
                '&:last-child': {
                    mr: 0,
                },
                height: 100,
                boxShadow: 'none',
            }}
        >
            <CardContent>
                <Typography
                    variant="body1"
                    gutterBottom
                    style={{
                        color: 'transparent',
                        background: REGULAR_MOCK,
                        fontSize: '2vmin',
                    }}
                >
                    Nom
                </Typography>
                <Grid container alignItems="baseline">
                    <Typography
                        variant="h5"
                        style={{
                            color: 'transparent',
                            background: REGULAR_MOCK,
                            fontSize: '2vmin',
                        }}
                    >
                        Number
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default function PrimaryKpiCard({
    name,
    number,
    units,
    difference,
    route = null,
    icon,
    type = KpiType.NUMBER,
    evolution
}: KpiCardType) {
    const navigate = useNavigate()

    return (
        <Card
            sx={{
                flex: 1,
                pr: '0.5vw',
                pl: '0.5vw',
                pt: '0.5vw',
                height: "100%",
                '&:hover': { cursor: route && 'pointer', background: route && LIGHT_GREY },
                '&:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            onClick={() => {
                if (route) navigate(route)
            }}
        >
            <CardContent
                sx={{
                    padding: '0px',
                    ':last-child': { padding: '0px' },
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {type === KpiType.GAUGE ?
                    <GaugeCard difference={difference}
                        name={name}
                        number={number}
                        units={units}
                        route={route}
                        icon={icon}
                        evolution={evolution} /> :
                    <KpiCard difference={difference}
                        name={name}
                        number={number}
                        units={units}
                        evolution={evolution}
                        icon={icon}
                    />}
            </CardContent>
        </Card>

    )
}
