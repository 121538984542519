import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { ActiveFsFilters } from '../filters/filtersTypes'
import { setLoading } from '../global/globalSlice'
import { AppThunk } from '../store'
import { PromotionsState } from './promotionsTypes'
import { EnvType } from '../user/userTypes'
import { getActiveFilters, isDashboardFiltered } from '../global/utils'

const initialState: PromotionsState = {
    impact: null,
    promoKpis: null,
    performance: null,
    cost: null,
    assortment: null
}

const promotionsSlice = createSlice({
    name: 'promotions',
    initialState,
    reducers: {
        setPromoImpact: (state, action: PayloadAction<any>) => {
            state.impact = action.payload
        },
        resetPromoImpact: (state) => {
            state.impact = null
        },
        setPromoCost: (state, action: PayloadAction<any>) => {
            state.cost = action.payload
        },
        resetPromoCost: (state) => {
            state.cost = null
        },
        setPromoPerformance: (state, action: PayloadAction<any>) => {
            state.performance = action.payload
        },
        resetPromoPerformance: (state) => {
            state.performance = null
        },
        setPromoKpis: (state, action: PayloadAction<any>) => {
            state.promoKpis = action.payload
        },
        setPromoAssortment: (state, action: PayloadAction<any>) => {
            state.assortment = action.payload
        },
        resetPromoAssortment: (state) => {
            state.assortment = null
        },
        resetPromoKpis: (state) => {
            state.promoKpis = null
        },
    },
})

export const {
    setPromoImpact,
    resetPromoImpact,
    setPromoKpis,
    resetPromoPerformance,
    setPromoPerformance,
    setPromoCost,
    resetPromoCost,
    setPromoAssortment,
    resetPromoAssortment,
    resetPromoKpis
} = promotionsSlice.actions
export default promotionsSlice.reducer

export const fetchPromoKPIs =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const isCompaign = window.location.href.includes("promo/")
            const lastSlashIndex = isCompaign && window.location.href.lastIndexOf("/");
            const compaignId = isCompaign ? window.location.href.slice(lastSlashIndex + 1) : " "
            const distributor = getState().global.env.name
            const envType = getState().global.env.type
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, false)

            const activeFilters = getState().filters[filtersName]
            const isFiltered = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isFiltered },
                ...{ isCompaign },
                ...{ compaignId }
            }

            dispatch(setLoading(true))
            dispatch(resetPromoKpis())
            try {
                const result = await client
                    .post(`/fs/promo/kpis?distrib=${distributor}`, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })
                dispatch(setPromoKpis(result.kpis))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }


export const fetchImpactPromo =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            dispatch(setLoading(true))

            const envType = getState().global.env.type
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, false)

            const activeFilters = getState().filters[filtersName]
            const isFiltered = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isFiltered },
            }

            dispatch(resetPromoImpact())
            try {
                const result = await client
                    .post(`/fs/promo/impact?distrib=${distributor}`, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setPromoImpact(result))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }


export const fetchPerformancePromo =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            dispatch(setLoading(true))

            const envType = getState().global.env.type
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, false)

            const activeFilters = getState().filters[filtersName]
            const isFiltered = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isFiltered },
            }

            dispatch(resetPromoPerformance())

            try {
                const result = await client
                    .post(`/fs/promo/performance?distrib=${distributor}`, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setPromoPerformance(result))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchCostPromo =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            dispatch(setLoading(true))

            const envType = getState().global.env.type
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, false)

            const activeFilters = getState().filters[filtersName]
            const isFiltered = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isFiltered },
            }

            dispatch(resetPromoCost())
            try {
                const result = await client
                    .post(`/fs/promo/cost?distrib=${distributor}`, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setPromoCost(result))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchAssortmentPromo =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            dispatch(setLoading(true))
            const envType = getState().global.env.type
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, false)

            const activeFilters = getState().filters[filtersName]
            const isFiltered = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isFiltered },
            }

            dispatch(resetPromoAssortment())

            try {
                const result = await client
                    .post(`/fs/promo/assortment?distrib=${distributor}`, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setPromoAssortment(result))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }