import React, { useSyncExternalStore } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PageNotFound from '../../GlobalPages/PageNotFound'
import { Box, Grid } from '@mui/material'
import { MobilePage } from './MobilePage'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../helpers/ExternalStore'
import { LIGHT_GREY } from '../../../ui/Colors'
import createRoute from './createRoute'
import { settingsPages } from './SettingsNavigation'
import { retailPages } from './RetailNavigation'
import { useAppSelector } from '../../../../redux/hooks'
import { KaryonModules } from '../../../../redux/user/userTypes'
import PageDistributorNotSet from '../../../../pages/private/Global/Import/PageDistributorNotSet'
import { EnvLayout } from './EnvLayout'
import { routerType } from './PublicNavigation'
import ChannelRouter from './routers/ChannelRouter'
import {
    fsDistributor,
    retailDistributor,
} from '../../../../helpers/distributorsList'
import { FsCrossRouter } from './routers/food-service/FsCrossRouter'
import { FranceFraisRouter } from './routers/food-service/FranceFraisRouter'
import { TransgourmetRouter } from './routers/food-service/TransgourmetRouter'
import { listOfFSDistributors } from '../../../../pages/private/Foodservice/types'
import { PassionFroidRouter } from './routers/food-service/PassionFroidRouter'
import { SodexoRouter } from './routers/food-service/SodexoRouter'
import { MetroRouter } from './routers/food-service/MetroRouter'
import { CercleVertRouter } from './routers/food-service/CercleVertRouter'
import { EpiSaveursRouter } from './routers/food-service/EpiSaveursRouter'
import { MultiAccountPage } from '../../GlobalPages/MultiAccountPage'

const WIDTH_LIMIT = 1200

interface Router {
    name: string
    router: (modules: KaryonModules[], companyModules: KaryonModules[]) => routerType[]
}

const foodserviceRouter: Router[] = [
    {
        name: listOfFSDistributors.FRANCE_FRAIS,
        router: FranceFraisRouter,
    },
    {
        name: listOfFSDistributors.TRANSGOURMETPLUS,
        router: FranceFraisRouter,
    },
    {
        name: listOfFSDistributors.EPISAVEURS,
        router: EpiSaveursRouter,
    }
    ,
    {
        name: listOfFSDistributors.PASSIONFROID,
        router: PassionFroidRouter,
    },
    {
        name: listOfFSDistributors.SODEXO,
        router: SodexoRouter,
    },
    {
        name: listOfFSDistributors.CERCLEVERT,
        router: CercleVertRouter,
    },
    {
        name: listOfFSDistributors.TRANSGOURMET,
        router: TransgourmetRouter,
    },
    { name: listOfFSDistributors.METRO, router: MetroRouter },
    {
        name: 'cross',
        router: FsCrossRouter,
    },
    {
        name: 'Commandes',
        router: FranceFraisRouter,
    },
]

const PrivateNavigation = () => {
    const { width } = useSyncExternalStore(subscribeToResize, getResizeSnapshot)
    const { user } = useAppSelector((state) => state.user)
    const isMobile = width < WIDTH_LIMIT

    return (
        <Box height="auto" minHeight="100%" bgcolor={LIGHT_GREY}>
            {isMobile ? (
                <Routes>
                    <Route path="*" element={<MobilePage />} />
                </Routes>
            ) : (
                <Grid>
                    {/* {!localStorage.getItem('cookiesAccepted') && (
                        <CookiesBanner />
                    )} */}
                    <Routes>
                        <Route path="/" element={<ChannelRouter />} />
                        <Route
                            path="/food-service"
                            element={<EnvLayout acceptedEnv={fsDistributor} />}
                        >
                            {foodserviceRouter.map(({ name, router }) => (
                                <Route path={name}>
                                    {createRoute(
                                        router(
                                            user.modules ? user.modules : [],
                                            user.companyModules
                                        ),
                                        `/food-service/${name}/`
                                    )}
                                </Route>
                            ))}
                        </Route>
                        <Route
                            path="retail"
                            element={
                                <EnvLayout acceptedEnv={retailDistributor} />
                            }
                        >
                            {createRoute(retailPages)}
                        </Route>
                        <Route path="/parametres">
                            {createRoute(settingsPages)}
                        </Route>
                        <Route
                            path="/multiAccount"
                            element={<MultiAccountPage />}
                        />

                        <Route
                            path="/distributeurnonconnecte"
                            element={<PageDistributorNotSet />}
                        />
                        <Route path="/404" element={<PageNotFound />} />
                        <Route path="*" element={<Navigate to="/404" />} />
                    </Routes>
                </Grid>
            )}
        </Box>
    )
}

export default PrivateNavigation
