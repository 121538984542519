import React, { useSyncExternalStore } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Box } from '@mui/material'
import { BRIC, SECONDARY } from '../../../ui/Colors'
import { useAppSelector } from '../../../../redux/hooks'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../helpers/ExternalStore'
import { backgroundColor } from '../../../../pages/private/Foodservice/Pages/ProductsPage/LineChartProduct'
import { formateLegend } from '../dashboard/utils/formateProductsData'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const LineChart = () => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const { unit } = useAppSelector((state) => state.global)
    const { legendChart, productsChart, productsTopFlop } = useAppSelector(
        (state) => state.global.fsCrossDashboardData
    )
    const formatedLegend = formateLegend(legendChart)

    const datasets = productsChart.map((product, index) => {
        let color = null
        if (product.name === productsTopFlop[0]._id) {
            color = SECONDARY
        } else if (
            product.name === productsTopFlop[productsTopFlop.length - 1]._id
        ) {
            color = BRIC
        } else {
            color = backgroundColor[index % 33]
        }

        return {
            label: product.name,
            data: [null, ...product.data, null],
            dataLastYear: [null, ...product.evolution, null],
            borderColor: color,
            backgroundColor: color,
        }
    })

    const data = {
        labels: [null, ...formatedLegend, null],
        datasets: datasets,
    }

    const options = {
        scales: {
            x: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.004,
        borderWidth: height * 0.003,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    color: '#000',
                    font: {
                        size: height * 0.014,
                        family: ['Cabin', 'sans-serif'],
                    },
                    boxWidth: height * 0.01,
                    boxHeight: height * 0.01,
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const value = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return `${model.dataset.label} : ${value} ${unit}`
                    },
                    footer: (model: any) => {
                        const evolution =
                            data.datasets[model[0].datasetIndex].dataLastYear[
                                model[0].dataIndex
                            ] === undefined
                                ? null
                                : Math.round(
                                    data.datasets[model[0].datasetIndex]
                                        .dataLastYear[model[0].dataIndex]
                                )

                        if (evolution === null) {
                            return 'Évolution : -'
                        }

                        if (evolution === 0) {
                            return ''
                        }
                        return evolution > 0
                            ? `Évolution : ↑ ${evolution} %`
                            : `Évolution : ↓  ${evolution} %`
                    },
                },
            },
        },
    }

    return (
        <Box
            sx={{
                height: '24vh',
                width: '60vw',
                display: 'inline-block',
            }}
        >
            <Line options={options as any} data={data} />
        </Box>
    )
}

export default LineChart
