import React from 'react'
import Map from '../../../../../components/features/MapContainer'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { useAppSelector } from '../../../../../redux/hooks'

export const DashboardMap = () => {
    const { env } = useAppSelector((state) => state.global)

    return (
        <ChartBlock title="Cartographie" link={`/retail/${env.name}/magasins`}>
            <Map />
        </ChartBlock>
    )
}
