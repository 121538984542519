import React, { useCallback, useEffect, useState } from 'react'
import {
    Field,
    type FieldAttributes,
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { DialogContent, Grid } from '@mui/material'
import { date, number, object, ref, string } from 'yup'
import {
    type StepConfig,
    ViewCreationMode,
    ViewTypes,
} from '../../../../redux/views/viewsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { TextInput } from '../../../ui/Inputs'
import { updateDataCampaign } from '../../../../redux/views/viewsSlice'
import { DialogFooter } from '../../Dialog/BasicFooterDialog'
import { PrimaryText } from '../../../ui/Text'
import { EnvType } from '../../../../redux/user/userTypes'
import { BLACK } from '../../../ui/Colors'
import FilterSelect from '../../filters/FilterSelect'
import { FiltersItem, MenuType } from '../../filters/utils'
import FilterCalendar from '../../filters/FilterCalendar'
import { getDefaultPeriod, getPlaceholderDates, monthAsArray } from '../../../../helpers/months'
import InventoryIcon from '@mui/icons-material/Inventory'
import { differenceInMonths } from '../../GlobalPages/Private/Period'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import { formateDateToString } from '../../utils/formateDateToString'
import { typesOfView } from '../../views/steps/FsSettingsStep'

interface OwnProps {
    step: number
    config: StepConfig
}

interface FormValues {
    name: string
    budget: number
    startDate: Date
    endDate: Date
    min: Date
    max: Date
    products: string[]
    distributors?: string[]
    clients: string[]
}

interface FormProps {
    activeValues: FormValues
    step: number
    config: StepConfig
    initialValues: {
        startDate: Date
        endDate: Date
    },
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
    filtersConfig: FiltersItem[]
}

const PureSettingsForm = ({
    setFieldValue,
    submitForm,
    config,
    step,
    filtersConfig,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {

    const [modified, setModified] = useState(false)
    const periodByDefault = getDefaultPeriod()
    const [printedPeriod, setPrintedPeriod] = useState<string | null>(
        periodByDefault
    )

    useEffect(() => {
        if (props.values.startDate && props.values.endDate) {
            const period = getPlaceholderDates(
                props.values.startDate,
                props.values.endDate
            )

            setPrintedPeriod(period)
        }
    }, [props.values])

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '42vh' }}>
                <Grid container direction="column">
                    <PrimaryText
                        bold
                        color={BLACK}
                        style={{ marginBottom: '3vh', marginTop: '1vh' }}
                    >
                        Paramétrez votre campagne :
                    </PrimaryText>
                    <Grid container direction="column">
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            m="3vh 0"
                            sx={{
                                '& .MuiFormHelperText-root': {
                                    fontSize: '1.6vh',
                                },
                            }}
                        >
                            <PrimaryText>Nom de votre campagne</PrimaryText>
                            <Field
                                size="small"
                                sx={{
                                    width: '20vw',
                                    '& div': {
                                        fontSize: '1.6vmin',
                                    },
                                }}
                                autoFocus
                                name="name"
                                type="text"
                                component={TextInput}
                            />
                        </Grid>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            m="3vh 0"
                            sx={{
                                '& .MuiFormHelperText-root': {
                                    fontSize: '1.6vh',
                                },
                            }}
                        >
                            <PrimaryText>Budget de cette campagne ?</PrimaryText>
                            <Field
                                size="small"
                                sx={{
                                    width: '20vw',
                                    '& div': {
                                        fontSize: '1.6vmin',
                                    },
                                }}
                                name="budget"
                                type="number"
                                component={TextInput}
                            />
                        </Grid>
                        <Grid
                            container
                            style={{
                                padding: 4,
                            }}
                        >
                            <PrimaryText width="30%">Mois et année : </PrimaryText>
                            <Grid sx={{ flex: 1, width: '100%' }}>
                                <FilterCalendar
                                    isModified={modified}
                                    placeholder={printedPeriod}
                                    setFieldValue={setFieldValue}
                                    setModified={setModified}
                                    start={props.values.startDate}
                                    end={props.values.endDate}
                                    errorEndDate={props.errors.endDate as string}
                                    errorStartDate={
                                        props.errors.startDate as string
                                    }
                                    isDialogFilter

                                />
                            </Grid>
                        </Grid>
                        {filtersConfig.map((filter) => (
                            <Grid
                                container
                                style={{
                                    padding: 4,
                                }}
                            >
                                <PrimaryText width="30%">
                                    {filter.placeholder} :{' '}
                                </PrimaryText>
                                <FilterSelect
                                    name={filter.name}
                                    icon={filter.icon}
                                    placeholder={filter.placeholder}
                                    type={filter.type}
                                    values={props.values[filter.name]}
                                    setFieldValue={setFieldValue}
                                    key={filter.id}
                                    env={EnvType.FOOD_SERVICE}
                                    isDialogFilter
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={
                    step === 0 ? config.handleReset : config.handlePrevious
                }
                leftButtonText={step === 0 ? 'ANNULER' : 'PRÉCÉDENT'}
                rightButtonFunc={async () => {
                    await submitForm()
                }}
                rightButtonText={
                    step === config.stepsNumber - 1 ? 'CRÉER MA CAMPAGNE' : 'SUIVANT'
                }
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        name: props.activeValues.name,
        budget: props.activeValues.budget,
        startDate: props.activeValues.startDate,
        endDate: props.activeValues.endDate,
        min: props.activeValues.min,
        max: props.activeValues.max,
        products: props.activeValues.products,
        clients: props.activeValues.clients,
    }),
    validationSchema: (props) => {
        const startMonth = props.activeValues.min.getMonth()
        const startYear = props.activeValues.min.getFullYear()
        const endMonth = props.activeValues.max.getMonth()
        const endYear = props.activeValues.max.getFullYear()
        return object().shape({
            name: string().required('Nom est un champ obligatoire'),
            budget: number().min(1, 'Votre budget doit être supérieur à 0')
                .required('Le montant du budget de la campagne est obligatoire'),
            startDate: date()
                .typeError('Entrez une date valide')
                .min(
                    props.activeValues.min,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de début'),
            endDate: date()
                .typeError('Entrez une date valide')
                .min(
                    ref('startDate'),
                    'Sélectionnez une date plus ancienne que celle de début'
                )
                .max(
                    props.activeValues.max,
                    `Sélectionnez des mois dans la plage de données disponible : ${monthAsArray[startMonth]} ${startYear} - ${monthAsArray[endMonth]} ${endYear}`
                )
                .required('Choississez une date de fin')
                .test(
                    'is-within-12-months',
                    'Vous ne pouvez pas sélectionner plus de 12 mois consécutifs.',
                    function (endDate) {
                        const { startDate } = this.parent; //Différent des pages car props.date.startDate est undefined
                        if (startDate && endDate) {
                            const monthsDifference = differenceInMonths(startDate, endDate);
                            return monthsDifference <= 11;
                        }
                        return true; // Si les dates ne sont pas définies, on passe.
                    }
                ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const CampaignStep = ({ step, config }: OwnProps) => {
    const dispatch = useAppDispatch()
    const { dataCampaign } = useAppSelector((state) => state.views)
    const { activeFsFilters, fsFilters } = useAppSelector((state) => state.filters)
    const { env } = useAppSelector((state) => state.global)

    const filtersConfig = [
        {
            id: 0,
            name: 'products',
            icon: <InventoryIcon />,
            placeholder: 'Références',
            type: MenuType.PRODUCTS,
        },
        {
            id: 2,
            name: 'clients',
            icon: <EmojiPeopleIcon />,
            placeholder: 'Types de clients',
            type: MenuType.CLIENTS,
        },
    ]

    const activeValues = {
        startDate: new Date(activeFsFilters.startDate),
        endDate: new Date(activeFsFilters.endDate),
        min: new Date(activeFsFilters.min),
        max: new Date(activeFsFilters.max),
        products: activeFsFilters.products,
        clients: activeFsFilters.clients,
        name: dataCampaign.name,
        budget: dataCampaign.budget,
    }

    const initialDates = {
        startDate: new Date(fsFilters.startDate),
        endDate: new Date(fsFilters.endDate),
    }

    activeValues.startDate.setHours(0, 0, 0)
    activeValues.endDate.setHours(0, 0, 0)
    activeValues.min.setHours(0, 0, 0)
    activeValues.max.setHours(0, 0, 0)
    initialDates.startDate.setHours(0, 0, 0)
    initialDates.endDate.setHours(0, 0, 0)

    const _updateData = useCallback(
        ({
            name,
            type,
            budget,
            environment,
            distributor,
            creationMode,
            clients,
            products,
            startDate,
            endDate,
            min,
            max
        }: {
            name: string
            type: ViewTypes
            budget: number
            environment: EnvType
            distributor: string
            creationMode: ViewCreationMode
            clients: string[]
            products: string[]
            startDate: string
            endDate: string
            min: string
            max: string
        }) =>
            dispatch(
                updateDataCampaign({
                    data: {
                        name,
                        type,
                        budget,
                        distributor,
                        environment,
                        creationMode,
                        filters: {
                            clients,
                            products,
                            startDate,
                            endDate,
                            min,
                            max
                        }
                    },
                })
            ),
        [dispatch]
    )

    const handleSubmit = (values: FormValues) => {
        const formatedDate = formateDateToString(
            values.startDate,
            values.endDate
        )
        _updateData({
            name: values.name,
            budget: values.budget,
            environment: env.type,
            type: ViewTypes.Dashboard,
            distributor: env.name,
            creationMode: ViewCreationMode.CAMPAIGN,
            products: values.products,
            clients: values.clients,
            startDate: formatedDate.start,
            endDate: formatedDate.end,
            min: activeFsFilters.min,
            max: activeFsFilters.max,
        })
        config.handleSubmit()
    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={{
                ...initialDates
            }}
            activeValues={activeValues}
            filtersConfig={filtersConfig}
        />
    )
}

export default CampaignStep