import React from 'react'
import { Grid } from '@mui/material'
import { DrillDownMenu } from '../../../../../../components/features/drillDowns/othersDrillDownComponents'
import { PromoInformationsTab } from './PromoInformationsTab'
import { PromoClientsTab } from './PromoClientsTab'
import { DrillDownContentProps } from '../../../../../../components/features/drillDowns/ClientFFRDrillDownContent'
import { useAppSelector } from '../../../../../../redux/hooks'
import { EnvName } from '../../../../../../redux/user/userTypes'

export const PromoDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
}: DrillDownContentProps) => {
    const { env } = useAppSelector((state) => state.global)

    const menu = env.name === EnvName.FRANCE_FRAIS ? ['KPI CARD', 'UTILISATEURS FINAUX'] : ['KPI CARD']

    return (
        <Grid height="66vh" width={'100%'}>
            <Grid
                sx={{
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .MuiTab-root.Mui-selected': {
                        color: '#666666',
                    },
                    '& .MuiTab-root': {
                        fontSize: { xs: '16px', md: '1.6vmin' },
                        padding: '1vw 2vh',
                        maxWidth: 'unset',
                    },
                }}
            >
                <DrillDownMenu
                    menus={menu}
                    handleChange={handleChange}
                    value={value}
                />
            </Grid>
            <PromoInformationsTab value={value} row={row} />
            {env.name === EnvName.FRANCE_FRAIS && <PromoClientsTab
                value={value}
                isFirstVisit={isFirstVisit}
                row={row}
            />}
        </Grid>
    )
}
