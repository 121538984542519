import React, { useState } from "react"
import PrivatePage from "../../../components/features/GlobalPages/Private/PrivatePage"
import { Card, Grid } from "@mui/material"
import { PagesName } from "../../../components/features/navigation/types/pages"
import { TertaryText, TitleBis } from "../../../components/ui/Text"
import { BLACK, PRIMARY } from "../../../components/ui/Colors"
import { PrimaryButton } from "../../../components/ui/Button"
import StarIcon from '@mui/icons-material/Star';
import { DialogData } from "../Global/Import/Import"
import { EnvName, EnvStatus } from "../../../redux/user/userTypes"
import ContactDialog from "../Global/Import/dialog/contact/ContactDialog"
// images
import PromoDemo from '../../../assets/PromoDemo.png'
import CostDemoPromo from '../../../assets/CostDemoPromo.png'
import PerfDemoPromo from '../../../assets/PerfDemoPromo.png'
import ImpactDemoPromo from '../../../assets/ImpactDemoPromo.png'
import CampaignDemoPromo from '../../../assets/CampaignDemoPromo.png'
import { Separator } from "../../../components/ui/Separator"

export const PlaybookPromo = () => {

    //For ContactDialog
    const resetState = {
        isOpen: false,
        envName: EnvName.MYDISTRIB,
        status: EnvStatus.INACTIVATED,
    }
    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)
    const openModaleContact = () => {
        setDialogContactData({ // TODO : Valider l'intérêt de reset l'env
            isOpen: true,
            envName: EnvName.MYDISTRIB,
            status: EnvStatus.INACTIVATED,
        })
    }
    return (
        <PrivatePage title="Module Promo" page={PagesName.PROMODEMO}>
            <ContactDialog
                isOpen={dialogContactData.isOpen}
                handleClose={() => {
                    setDialogContactData(resetState)
                }}
            />
            <Grid p="1vw">
                <Card sx={{ height: '100%', }}>
                    <Grid container direction="row" wrap="nowrap" justifyContent="center" mb="12vh" mt="4vh">
                        <Grid width="30%" mt="2vh" mr="2vw">
                            <Grid container alignItems="center">
                                <StarIcon sx={{ color: "#FFD233", fontSize: "2vmin" }} />
                                <TertaryText bold color={BLACK} ml="0.5vw" >Débloquer le module promo</TertaryText>
                            </Grid>
                            <TitleBis bold color={PRIMARY} mb="1vh" mt="3vh"> Optimisez votre stratégie promotionnelle grâce à une analyse fine de vos performances</TitleBis>
                            <TertaryText color={BLACK}>Grâce au module promo de KaryonFood, suivez les performances de votre stratégie promotionnelle. </TertaryText>
                            <TertaryText color={BLACK}>Vous pouvez désormais mesurer en détail la contribution des vos promotions à votre croissance mais également comprendre comment votre cible utilisateur répond à vos mécaniques. </TertaryText>
                            <TertaryText color={BLACK} mb="3vh">Soyez en contrôle, adaptez vos actions tout en suivant vos coûts promo.</TertaryText>
                            <Grid container justifyContent="center">
                                <PrimaryButton onClick={(e) => {
                                    openModaleContact()
                                }}>Contacter l'équipe commerciale</PrimaryButton>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" width="50%">
                            <img style={{ margin: "1vh", height: "fit-content", width: "90%" }} src={PromoDemo} />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" wrap="nowrap" justifyContent="center" mb="12vh" mt="12vh">
                        <Grid container width="40%" justifyContent="center" >
                            <img style={{ margin: "1vh", height: "fit-content", maxHeight: "30vh" }} src={ImpactDemoPromo} />
                        </Grid>
                        <Grid width="40%" ml="2vw" >
                            <TertaryText fontSize={{ sx: "20px", md: "3vmin" }} bold color={PRIMARY} mb="1vh" > Mes promotions ont-elles permis d’obtenir des ventes incrémentales ?</TertaryText>
                            <TertaryText color={BLACK}>Identifiez concrètement l’impact de vos actions promotionnelles. Suivez <b>le poids de vos promotions</b> et vos les stocks pour éviter <b>les effets d’aubaine</b> chez le distributeur.</TertaryText>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap" justifyContent="center" mb="12vh">
                        <Grid width="40%" mr="2vw" >
                            <TertaryText fontSize={{ sx: "20px", md: "3vmin" }} bold color={PRIMARY} mb="1vh"  > Comment ma cible utilisateur a-t-elle réagi à mes plans promotionnels ?</TertaryText>
                            <TertaryText color={BLACK}>Cela nécessite-t-il d’affiner mes mécaniques promotionnelles ? </TertaryText>
                            <TertaryText color={BLACK}>Découvrez quels types d’utilisateurs ont été sensibles à vos promotions puis <b>optimisez vos campagnes</b> en adaptant vos efforts à leurs attentes et à l’évolution de leur panier moyen mensuel.
                            </TertaryText>
                            <TertaryText color={BLACK}>
                                Analysez les résultats de votre éventail client et <b>adaptez vos investissements promotionnels</b> dans la durée.</TertaryText>
                        </Grid>
                        <Grid container width="40%" justifyContent="center" alignItems="center" >
                            <img style={{ margin: "1vh", height: "fit-content", maxHeight: "30vh" }} src={PerfDemoPromo} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap" justifyContent="center" mb="12vh">
                        <Grid container width="40%" justifyContent="center" alignItems="center" >
                            <img style={{ margin: "1vh", height: "fit-content", maxHeight: "30vh" }} src={CampaignDemoPromo} />
                        </Grid>
                        <Grid width="40%" ml="2vw">
                            <TertaryText fontSize={{ sx: "20px", md: "3vmin" }} bold color={PRIMARY} mb="1vh" > Quelles sont les logiques d’assortiments performantes ? Sur quelles références développer des campagnes ?</TertaryText>
                            <TertaryText color={BLACK}>Quelles combinaisons fonctionnent le mieux ? </TertaryText>
                            <TertaryText color={BLACK}><b>Analysez les paniers moyens par référence, assortiment et par campagne</b> pour distinguer les assortiments les plus promophiles. </TertaryText>
                            <TertaryText color={BLACK}>Mettez en avant les produits qui génèrent le plus de résultats et <b>engagez des campagnes durables et efficaces</b>.</TertaryText>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap" justifyContent="center" mb="10vh">
                        <Grid width="40%" mr="2vw">
                            <TertaryText fontSize={{ sx: "20px", md: "3vmin" }} bold color={PRIMARY} mb="1vh" > Votre budget promotionnel est-il optimisé au regard des effets mesurés ?</TertaryText>
                            <TertaryText color={BLACK}>Gardez une vision claire sur <b>les coûts et les gains liés à vos initiatives.</b> </TertaryText>
                            <TertaryText color={BLACK}>Découvrez combien coûte l’acquisition de nouveaux utilisateurs mais aussi sur votre volume d’affaires incrémental. </TertaryText>
                            <TertaryText color={BLACK}>En ayant l’œil sur votre ROI, vous vous projetez et optimisez vos coûts.</TertaryText>
                        </Grid>
                        <Grid container width="40%" justifyContent="center" alignItems="center">
                            <img style={{ margin: "1vh", height: "fit-content", maxHeight: "30vh" }} src={CostDemoPromo} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" mb="5vh">
                        <PrimaryButton onClick={(e) => {
                            openModaleContact()
                        }}>Contacter l'équipe commerciale</PrimaryButton>
                    </Grid>
                </Card>
            </Grid>
        </PrivatePage >
    )
}
