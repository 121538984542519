import React from "react"
import { Chip, Grid } from "@mui/material"
import { BLACK, BRIC, GREEN, RED, SECONDARY } from "../ui/Colors"
import { PrimaryText } from "../ui/Text"
import Tendency from "../ui/Tendency"
import { Units } from "../../redux/global/globalTypes"

export const ChipCard = ({ productName, number, isTop, unit }: { productName: string, number: number, isTop: boolean, unit: Units }) => {

    const colorChip = isTop ? SECONDARY : BRIC
    const colorText = isTop ? GREEN : "#803F31"
    const colorTendency = number > 0 ? GREEN : RED
    const chipTitle = isTop ? "La + génératrice de volume" : "La - génératrice de volume"

    return (
        <Grid container flex={1} direction="column" >
            <Chip
                label={chipTitle}
                sx={{
                    color: colorText,
                    background: colorChip,
                    width: "75%",
                    mb: "1vh"
                }}
            />
            <PrimaryText color={BLACK} >{productName}</PrimaryText>
            <Grid container mt="1vh" direction="row" width="100%" >
                <Grid >
                    <PrimaryText color={colorTendency} >Incrémental =</PrimaryText>
                </Grid>
                <Grid>
                    <Tendency difference={number} unit={unit} ></Tendency>
                </Grid>
            </Grid>
        </Grid >
    )
}