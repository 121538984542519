import { Dialog } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
    closeCampaignCreator,
    handleCampaignCreation,
} from '../../../redux/views/viewsSlice'
import {
    ResponseViewsCreation,
    type StepConfig,
    ViewCreationMode,
    ViewsSteps,
} from '../../../redux/views/viewsTypes'
import { DialogHeader } from '../Dialog/BasicDialog'
import CampaignStep from './steps/CampaignStep'

const stepsSync: Record<
    string,
    (step: number, configCampaign: StepConfig) => JSX.Element
> = {
    [ViewsSteps.CampaignFS]: (step: number, configCampaign: StepConfig) => (
        <CampaignStep step={step} config={configCampaign} />
    ),

}

const CampaignDialog = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { openAddCampaign, configCampaign } = useAppSelector((state) => state.views)

    const _closeCampaignCreator = useCallback(
        () => dispatch(closeCampaignCreator()),
        [dispatch]
    )
    const _handleCreation = useCallback(
        async () => await dispatch(handleCampaignCreation()),
        [dispatch]
    )
    const [activeStep, setActiveStep] = useState<number>(0)

    const handleClose = () => {
        _closeCampaignCreator()
    }

    const handleReset = () => {
        _closeCampaignCreator()
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const handleNext = () => {
        if (activeStep === configCampaign.steps.length - 1) {
            handleSubmit()
        } else {
            setActiveStep(activeStep + 1)
        }
    }

    const handleSubmit = () => {
        _handleCreation()
            .then((view: ResponseViewsCreation) => {
                navigate(
                    `/${view.environment}/${view.distributor}/promo/${view.insertedId}`,
                )
            })
        setActiveStep(0)
        handleClose()
    }

    const StepComponent = stepsSync[configCampaign.steps[activeStep]]

    return (
        <Dialog
            open={openAddCampaign}
            fullWidth={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title="Que souhaitez-vous observer ?"
                setOpen={handleClose}
            />
            {StepComponent(activeStep, {
                handleNext,
                handlePrevious,
                handleReset,
                handleSubmit,
                stepsNumber: configCampaign.steps.length,
            })}
        </Dialog>
    )
}

export default CampaignDialog