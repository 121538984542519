import React from "react"
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid"
import { PrimaryText, SecondaryText } from "../../../../../../components/ui/Text"
import { BLACK } from "../../../../../../components/ui/Colors"
import NumberWithSpace from "../../../../../../components/ui/NumberWithSpace"
import { ArrowTendency } from "../../../../../../components/ui/ArrowTendency"
import { Grid } from "@mui/material"
import { Units } from "../../../../../../redux/global/globalTypes"

export const columnsCampaignsTabV1 = (): GridColDef[] => {
    return [{
        field: 'name',
        headerName: 'Nom',
        flex: 1.4,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>{params.value}</SecondaryText>
            )
        },
        sortable: false,
    }]
}

export const columnsCampaignsTab = (unit: Units): GridColDef[] => {
    return [
        {
            field: 'name',
            headerName: 'Nom',
            flex: 1.4,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {

                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'stockage',
            headerName: `Stockage`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.row.data.stockage > 0 ? "+" : ""} {Math.round(params.row.data.stockage).toString().replace('.', ',')} %</SecondaryText>
                )
            },
            sortable: false,
        },
        {
            field: 'incremental',
            headerName: `Incrémental (${unit})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.row.data.incremental ? (
                    <Grid container alignItems="center" ml="0.8em" justifyContent="center">
                        <SecondaryText color={BLACK}>{params.row.data.incremental > 0 ? "+" : ""} {Math.round(params.row.data.incremental).toString().replace('.', ',')} </SecondaryText>
                    </Grid >
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        }, {
            field: 'evolUF',
            headerName: `Evo UF`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return params.row.data.evolUF ? (
                    <Grid container alignItems="center" ml="0.8em">
                        <ArrowTendency number={params.row.data.evolUF} />
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace
                                number={Math.round(params.row.data.evolUF)}
                            />

                        </SecondaryText>
                    </Grid>
                ) : (
                    <PrimaryText>-</PrimaryText>
                )
            },
            sortable: false,
        },
        // {
        //     field: 'cost',
        //     headerName: `Coût VA (€/${unit})`,
        //     flex: 1,
        //     editable: false,
        //     renderHeader: (params: GridColumnHeaderParams) => {
        //         return (
        //             <PrimaryText color={BLACK}>
        //                 {params.colDef.headerName}
        //             </PrimaryText>
        //         )
        //     },
        //     renderCell: (params: GridRenderCellParams) => {
        //         return params.value ? (
        //             <Grid container alignItems="center" ml="0.8em">
        //                 <SecondaryText color={BLACK}>
        //                     <NumberWithSpace
        //                         number={params.value.toString().replace('.', ',')}
        //                     />

        //                 </SecondaryText>
        //             </Grid>
        //         ) : (
        //             <PrimaryText>-</PrimaryText>
        //         )
        //     },
        //     sortable: false,
        // }
    ]
} 