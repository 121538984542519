import { Grid } from "@mui/material"
import React from "react"
import { LegendColor, StackedBarChart } from "../../../../../../components/features/BarChart"
import { Units } from "../../../../../../redux/global/globalTypes"
import { PrimaryText } from "../../../../../../components/ui/Text"
import SquareIcon from '@mui/icons-material/Square';
import { StockageNumber } from "../../../../../../components/features/NumberCard"
import { BLACK } from "../../../../../../components/ui/Colors"
import { StockageChart } from "./configsPromoPage"


export const StockageComponent = ({ chart, labelGlobal, labelPart, labelSecondBar, globalTendency, stockagePercentage }: { chart: StockageChart, labelGlobal: string, labelPart: string, labelSecondBar: string, globalTendency: number, stockagePercentage: number }) => {

    return (
        <Grid container wrap="nowrap">
            <Grid>
                <StackedBarChart
                    global={chart.sellInGlobal}
                    globalLabel={labelGlobal}
                    part={chart.sellInPromo}
                    partLabel={labelPart}
                    secondBar={chart.sellOut}
                    secondBarLabel={labelSecondBar} />
            </Grid>
            <Grid container>
                <Grid container direction="row" wrap="nowrap">
                    <StockageNumber number={stockagePercentage} text={"Stockage"} unit={Units.percent} />
                    <StockageNumber number={globalTendency} text={"Trend"} unit={Units.percent} />
                </Grid>
                <Grid>
                    <StockageChartBarLegend labelGlobal={labelGlobal} labelPart={labelPart} labelSecondBar={labelSecondBar} />
                </Grid>
            </Grid>
        </Grid >)
}


const StockageChartBarLegend = ({ labelGlobal, labelPart, labelSecondBar }: { labelGlobal: string, labelPart: string, labelSecondBar: string }) => {

    return (
        <Grid container  >
            <Grid container alignItems="center" >
                <SquareIcon fontSize="small" sx={{ color: LegendColor.GLOBAL }} />
                <PrimaryText color={BLACK} > {labelGlobal} </PrimaryText>
            </Grid>
            <Grid container alignItems="center">
                <SquareIcon fontSize="small" sx={{ color: LegendColor.PART }} />
                <PrimaryText color={BLACK} > {labelPart} </PrimaryText>
            </Grid>
            <Grid container alignItems="center">
                <SquareIcon fontSize="small" sx={{ color: LegendColor.SECONDBAR }} />
                <PrimaryText color={BLACK} > {labelSecondBar} </PrimaryText>
            </Grid>
        </Grid >
    )
}