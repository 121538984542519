// BASICS
export const RED = '#d84848'
export const GREEN = '#41AF2F'
export const ORANGE = 'orange'
export const WHITE = '#FFF'
export const BLACK = '#000'
export const BRIC = "#FF927A"

// APP
export const PRIMARY = '#0C50D3'
export const SECONDARY = '#CFFF97'
export const TERTIARY = '#FFA461'

export const LIGHT_BLUE = '#EBEFF2'
export const DARK_BLUE = '#2B354E '
export const LAVENDER = '#ADB2D3'
export const MATERIAL_BLUE = '#0075FF'
export const ERROR_RED = '#d64444'

// GREY GRADIENT
export const LIGHT_GREY = '#F5F5F5'
export const MEDIUM_GREY = '#ACACAC'
export const GREY = '#d1d1d1'
export const DARK_GREY = '#6f6f6f'

// MOCK COLORS
export const LIGHT_MOCK = '#EEEEEE'
export const REGULAR_MOCK = '#DDDDDD'
export const DARK_MOCK = '#BBBBBB'
